import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function MemberDetails({ source }) {
  const featuredImage = {
    data: source.featuredImage?.node?.localFile?.childImageSharp
      ?.gatsbyImageData,
    alt: source.featuredImage?.node?.alt || ``,
  }

  console.log(source)
  //   role
  //           speciality
  //           awards
  //           education
  //           experience
  //           lecture
  //           role
  //           speciality
  //           fieldGroupName
  //           qutoe {
  //             fieldGroupName
  //             description
  //             title
  //           }
  return (
    <>
      <div className="bg-gray-100 relative">
        {source.featuredImage && (
          <>
            <div style={{ paddingBottom: "120%" }}></div>
            <GatsbyImage
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                inset: 0,
                objectFit: "cover",
              }}
              image={featuredImage.data}
              alt={featuredImage.alt}
            />
          </>
        )}
      </div>
      <div className="px-4 lg:px-8">
        <div className="py-4 mt-3 lg:mt-4 text-center">
          {source.consultant?.speciality && (
            <h4 className="text-primary lg:mb-3 lg:text-lg">
              {source.consultant.speciality}
            </h4>
          )}
          <h3 className="text-gray-900 font-medium text-lg lg:text-2xl">
            {source.title}
          </h3>
          <p className="text-gray-500 lg:mt-1">{source.consultant.role}</p>
        </div>
        {(source.consultant?.qutoe?.title ||
          source.consultant?.qutoe?.description) && (
          <div className="py-4">
            {source.consultant?.qutoe?.title && (
              <h3 className="font-serif font-bold text-lg lg:text-2xl break-words">
                {source.consultant.qutoe.title}
              </h3>
            )}
            {source.consultant?.qutoe?.description && (
              <p className="text-gray-500 break-words mt-3">
                {source.consultant.qutoe.description}
              </p>
            )}
          </div>
        )}
        <div className="py-8">
          {source.consultant?.awards && (
            <div className="mt-8 first:mt-0">
              <h4 className="text-gray-900 font-medium mb-3 text-lg">
                주요 수행실적
              </h4>
              <ul className="text-gray-500 list-disc ml-5">
                {source.consultant.awards.split("\n").map((item, index) => {
                  return (
                    <li className="break-words" key={`awards-${index}`}>
                      {item}
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {source.consultant?.lecture && (
            <div className="mt-8 first:mt-0">
              <h4 className="text-gray-900 font-medium mb-3 text-lg">
                주요 강의실적
              </h4>
              <ul className="text-gray-500 list-disc ml-5">
                {source.consultant.lecture.split("\n").map((item, index) => {
                  return (
                    <li className="break-words" key={`lecture-${index}`}>
                      {item}
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {source.consultant?.experience && (
            <div className="mt-8 first:mt-0">
              <h4 className="text-gray-900 font-medium mb-3 text-lg">
                주요 경력사항
              </h4>
              <ul className="text-gray-500 list-disc ml-5">
                {source.consultant.experience.split("\n").map((item, index) => {
                  return (
                    <li className="break-words" key={`experience-${index}`}>
                      {item}
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {source.consultant?.education && (
            <div className="mt-8 first:mt-0">
              <h4 className="text-gray-900 font-medium mb-3 text-lg">
                최종학력
              </h4>
              <ul className="text-gray-500 list-disc ml-5">
                {source.consultant.education.split("\n").map((item, index) => {
                  return (
                    <li className="break-words" key={`education-${index}`}>
                      {item}
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
