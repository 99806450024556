import React, { useState, useEffect } from "react"
import { SwipeableDrawer } from "@mui/material"
import { XIcon } from "@heroicons/react/solid"

export default function MemberDrawer({ open, onClose, children }) {
  const [drawerSize, setDrawerSize] = useState("100%")

  const handleResize = () => {
    if (window.innerWidth <= 640) {
      setDrawerSize("100%")
    } else {
      setDrawerSize("480px")
    }
  }

  useEffect(() => {
    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onOpen={() => console.log("open")}
      onClose={onClose}
      PaperProps={{
        sx: { width: drawerSize },
      }}
    >
      <div className="relative">
        <div className="absolute left-0 top-0 z-20">
          <button className="py-5 px-4 inline-block" onClick={onClose}>
            <XIcon className="w-6 h-6 text-gray-500" />
          </button>
        </div>
        <div>{children}</div>
      </div>
    </SwipeableDrawer>
  )
}
